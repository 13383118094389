import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PerfilprogramadorComponent } from './pages/perfilprogramador/perfilprogramador.component';
import { PerfildeportivoComponent } from './pages/perfildeportivo/perfildeportivo.component';

@NgModule({
  declarations: [
    AppComponent,
    PerfilprogramadorComponent,
    PerfildeportivoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
