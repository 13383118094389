import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfildeportivo',
  templateUrl: './perfildeportivo.component.html',
  styleUrls: ['./perfildeportivo.component.css']
})
export class PerfildeportivoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
