import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PerfildeportivoComponent } from './pages/perfildeportivo/perfildeportivo.component';
import { PerfilprogramadorComponent } from './pages/perfilprogramador/perfilprogramador.component';

const routes: Routes = [
  {
    path: 'perfildeportivo',
    component: PerfildeportivoComponent
  },
  {
    path: 'perfilprogramador',
    component: PerfilprogramadorComponent
  },
  {
    path: '**',
    redirectTo: 'perfilprogramador'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: `reload`})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
